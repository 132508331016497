<template>
  <div class="wrapper">
    <MainHeader
      :description="$t('pages.argumentaai.description')"
      :module="$t('pages.argumentaai.module')"
      :page-title="$t('pages.argumentaai.title')"
    />
    <wrapper class="content-page">
      <div class="container-filters" />
      <div class="doc-wrapper">
        <ColorDocsMiniature
          v-for="item in allDocuments"
          :key="item.id"
          card-color="#5B7A95"
          :card-date="item.publicationAt"
          :card-download="getFileFullUrl(item.fileLink)"
          :card-type="item.title"
          :card-year="cardYear"
        />
      </div>
    </wrapper>

    <EmptyState
      v-if="totalDocuments === 0"
      :image-src="require(`@/assets/img/examSearch.svg`)"
      img-empty-result-class
    >
      <h6 class="empty-desc">
        {{ $t('pages.argumentaai.noResult') }}
      </h6>
    </EmptyState>
  </div>
</template>

<script>
import MainHeader from '@/components/shared/MainHeader.vue'
import ColorDocsMiniature from '@/components/shared/ColorDocsMiniature.vue'
import EmptyState from '@/components/shared/EmptyState.vue'

import documentsService from '@/services/documentsService'

import { getFileFullUrl } from '@/utils/files'

export default {
  name: 'Argumentaai',
  components: {
    ColorDocsMiniature,
    EmptyState,
    MainHeader,
  },
  data() {
    return {
      allDocuments: [],
      loading: false,
      totalDocuments: 0,
      cardYear: null,
      docSearch: null,
      rootCategoryKey: process.env.VUE_APP_DOCUMENTS_ARGUMENTA_AI,
    }
  },
  created() {
    this.getDocuments()
  },
  methods: {
    async getDocuments() {
      try {
        const { data } = await documentsService.getDocumentsByRootCategoryId(
          this.rootCategoryKey,
        )

        if (data) {
          this.totalDocuments = data.documents.length || 0

          if (this.totalDocuments > 0) {
            data.documents.forEach((file) => {
              const fileTitle = file.title ? file.title : ''
              const searchTerm = this.docSearch ? this.docSearch : ''
              fileTitle.toLowerCase()
              searchTerm.toLowerCase()

              if (fileTitle.includes(searchTerm)) {
                this.allDocuments.push(file)
              }
            })
          }
        }
      } catch (error) {
        this.$toasted.global.error({
          message: `Erro ao carregar dados. ${error}`,
        })
      }
    },
    getFileFullUrl,
  },
}
</script>

<style lang="sass">
.sas-wrapper.content-page
  padding-bottom: 20px
  background: transparent
  padding-bottom: 20px

.content-page
  .sas-box
    border-radius: 0px !important

    +mq-l--mf
      border-radius: $border-radius-m !important

.doc-wrapper
  display: grid
  grid-gap: 32px

  +mq-m--mf
    grid-template-columns: repeat(2, 1fr)

  +mq-l--mf
    grid-template-columns: repeat(4, 1fr)

.main-subtitle
  display: inline-block
  margin-bottom: 36px
  font-size: 22px
  line-height: 28px
  color: #666E75

.simple-separator
  border: 0
  border-bottom: 1px solid #C4C4C4
  margin: 23px 0

.books-filter
  margin: 24px 0 32px 0
  display: inline

  .btn-content
    display: inline
    margin-right: 16px

.btn-book.sas-button
  margin-bottom: 14px
  box-shadow: none
  border: 1px solid #53595F

  &:not(:last-of-type)
    margin-right: 16px

  &:focus
    box-shadow: none !important

  &.active-book
    background: #666E75
    border: 1px solid #666E75 !important
    color: $color-white !important

.container-filters
  width: 100%
  display: flex
  align-items: flex-start
  flex-flow: wrap
  margin-bottom: 24px
  padding-top: 20px

  +mq-m--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px

  +mq-l--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px
</style>
